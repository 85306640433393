import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ shadows, shape }) => ({
    globalLoadingContainer: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    globalLoadingIndicator: {
        minWidth: 200,
        minHeight: 200,
        boxShadow: shadows[3],
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: shape.borderRadius,
    },
}));

export const GlobalLoader: React.FC = () => {
    const styles = useStyles();
    return (
        <Box className={styles.globalLoadingContainer}>
            <Box className={styles.globalLoadingIndicator}>
                <CircularProgress size={"50%"} />
            </Box>
        </Box>
    );
};