import React, { Fragment, useMemo } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { Table } from "~/components/Table";
import { GlobalLoader } from "@settings/GlobalLoader";

import { ViewComponent } from "@settings/view";

import { useAuth } from "@api/auth";

import { ctaColumns } from "./columns";
import { useCTAContext } from "./context";
import { Redirect } from "~/components/Redirect";

const useStyles = makeStyles(({ shadows, shape }) => ({
    content: {
        position: "relative",
        display: "flex",
        flexFlow: "row nowrap",
        height: "100%",
    },
    ctaContent: {
        width: "100%",
        minHeight: "100%",
        overflow: "hidden",
        display: "flex",
        flexFlow: "column nowrap",
        position: "relative",
    },
    overrideDisplay: {
        display: "flex",
        padding: "5%",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
}));

export const CTADisplay: ViewComponent = ({ navigate }) => {
    const { user } = useAuth();
    const {
        displayGroup,
        loading,
    } = useCTAContext();
    const styles = useStyles();

    const content = useMemo(() => {
        if (!user?.autocorpAdmin) {
            return <Redirect to="/leads/" />;
        }

        if (loading) {
            return <GlobalLoader />;
        // } else if (criticalError) {
        //     return (
        //         <CriticalError
        //             message={"An error occurred while fetching the cta list"}
        //             error={criticalError}
        //         />
        //     );
        } else {
            return (
                <Fragment>
                    <Table
                        compact
                        columns={ctaColumns}
                        rows={displayGroup?.rows || []}
                        loading={loading}
                        emptyMessage={"This company does not have any ctas."}
                    />
                </Fragment>
            );
        }
    }, [
        displayGroup,
        loading,
        user,
    ]);

    return (
        <Box className={styles.content}>
            <Box className={styles.ctaContent}>
                {content}
            </Box>
        </Box>
    );
};