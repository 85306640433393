import React from "react";

import { LayoutComponent } from "~/model/layout";
import { PrivatePaths } from "~/model/paths";
import { ITabProps } from "~/components/Tab/types";
import TabbedNav from "~/components/TabbebNav";

export const Layout: LayoutComponent<never> = ({ location, pageProps: { companyId } }) => {

    const tabs: ITabProps[] = [
        {
            location,
            title: "General",
            path: `/admin/dealer-management/${companyId}/general` as PrivatePaths,
            disabled: true,
        },
        {
            location,
            title: "CTAs",
            path: `/admin/dealer-management/${companyId}/ctas` as PrivatePaths,
        },
        {
            location,
            title: "Products",
            path: `/admin/dealer-management/${companyId}/products` as PrivatePaths,
        },
    ];

    return (
        <TabbedNav
            tabs={tabs}
        />
    );
};